.auth-form-input {
    border-radius: 10px !important;
    box-shadow: 0 0.0625rem 0.5rem rgb(0 0 0 / 10%) !important;
}

// .form-input-label-style:focus-within .ant-form-item-label {
//     color: $primary-color !important;
//     transform-origin: top left;
//     transition: all 0.2s ease-out;
//     transform: translate(0, -24px);
//     z-index: 2 !important;
// }

// .form-input-label-style {
//     .ant-form-item-label {
//         padding: 0px !important;
//         left: 15px !important;
//         top: 35px !important;
//         z-index: 2 !important;

//         label {
//             background-color: $white !important;
//             padding: 0px 5px !important;
//             font-size: 13px !important;
//         }
//     }
// }

.form-input-label-style {
    .ant-form-item-label {
        padding: 0px !important;
        left: 15px !important;
        top: 11px !important;
        z-index: 2 !important;

        label {
            background-color: $white !important;
            padding: 0px 5px !important;
            font-size: 13px !important;
        }

        .ant-input:focus {
            label {
                color: $primary-color !important;
            }
        }
    }
}

.form-input-label-style:focus-within label {
    color: $primary-color !important;
}