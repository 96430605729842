.site-layout-content {
    padding-left: 220px !important;
}

.site-layout-content-collapse {
    padding-left: 100px !important;
}

.site-layout-content, .site-layout-content-collapse {
    padding-top: 60px !important;
    padding-right: 20px !important;
    padding-bottom: 20px !important;
    background-color: $gray-100 !important;
    transition: .2s !important;
}