/* width */
.submenu-layout::-webkit-scrollbar, .submenu-layout-collapsed::-webkit-scrollbar{
    width: 5px !important;
    height: 5px !important;
    display: none !important;
}

/* Track */
.submenu-layout::-webkit-scrollbar-track, .submenu-layout-collapsed::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px $gray-100 !important; 
    border-radius: 10px !important;
}

/* Handle */
.submenu-layout::-webkit-scrollbar-thumb, .submenu-layout-collapsed::-webkit-scrollbar-thumb {
    background: $primary-color !important; 
    border-radius: 10px !important;
    // visibility: hidden !important;
}

/* Handle on hover */
.submenu-layout::-webkit-scrollbar-thumb:hover, .submenu-layout-collapsed::-webkit-scrollbar-thumb:hover {
    background: darken($color: $primary-color, $amount: 10) !important; 
}

.header-layout {
    padding: 0px !important;
    background: $white !important;
    height: $header-height !important;
    position: fixed;
    width: 100%;
    z-index: 20;

    -moz-box-shadow:0 5px 5px rgba(182, 182, 182, 0.75);
    -webkit-box-shadow: 0 5px 5px rgba(182, 182, 182, 0.75);
    box-shadow: 0 5px 5px rgba(182, 182, 182, 0.75);

    .logo-part {
        width: $sider-width !important;
        transition: 0.2s;
        text-align: center;
    }

    .logo-part-collapsed {
        width: $sider-collapsed-width !important;
        transition: 0.2s;
        text-align: center;
    }

    .submenu-layout {
        width: calc(100% - $sider-width - $option-width) !important;
    }

    .submenu-layout-collapsed {
        width: calc(100% - $sider-collapsed-width - $option-width) !important;
    }

    .submenu-layout, .submenu-layout-collapsed {
        transition: 0.2s;
        overflow-x: auto !important;

        ul {
            justify-content: start !important;

            li {
                overflow: unset;
            }
        }
    }

    .option-part {
        width: $option-width !important;
        padding-right: 10px !important;
    }
}