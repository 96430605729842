#root {
    height: 100%;
}

body {
    background-color: $gray-100 !important;
}

// font
@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/montserrat-v15-latin-regular.ttf');
}

@font-face{
    font-family: 'Khmer OS Battambang';
    src: url('../fonts/KhmerOSbattambang.ttf');
}

@font-face{
    font-family: 'Khmer OS Muol';
    src: url('../fonts/KhmerOSmuol.ttf');
}

@font-face{
    font-family: 'Khmer OS Muol Light';
    src: url('../fonts/KhmerOSmuollight.ttf');
}

// ScrollBar
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px $gray-500; 
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $gray-400; 
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $gray-600; 
}

// Margin
@for $i from 0 through 100 {
    .m-#{$i} {
        margin: #{$i}px !important;
    }
}

/* Margin Top */
@for $i from 0 through 100 {
    .mt-#{$i} {
        margin-top: #{$i}px !important;
    }
}

/* Margin Bottom */
@for $i from 0 through 100 {
    .mb-#{$i} {
        margin-bottom: #{$i}px !important;
    }
}
  
/* Margin Left */
@for $i from 0 through 100 {
    .ml-#{$i} {
        margin-left: #{$i}px !important;
    }
}
  
/* Margin Right */
@for $i from 0 through 100 {
    .mr-#{$i} {
        margin-right: #{$i}px !important;
    }
}

// Padding
@for $i from 0 through 100 {
    .p-#{$i} {
        padding: #{$i}px !important;
    }
}

/* Padding Top */
@for $i from 0 through 100 {
    .pt-#{$i} {
        padding-top: #{$i}px !important;
    }
}

/* Padding Bottom */
@for $i from 0 through 100 {
    .pb-#{$i} {
        padding-bottom: #{$i}px !important;
    }
}

/* Padding Left */
@for $i from 0 through 100 {
    .pl-#{$i} {
        padding-left: #{$i}px !important;
    }
}

/* Padding Right */
@for $i from 0 through 100 {
    .pr-#{$i} {
        padding-right: #{$i}px !important;
    }
}

// Font Size
@for $i from 0 through 100 {
    .fs-#{$i} {
        font-size: #{$i}px !important;
    }
}

// Min Height
@for $i from 0 through 50 {
    .min-height-#{$i} {
        min-height: #{$i}px !important;
    }
}

.w-100 {
    width: 100% !important;
}

.h-100 {
    height: 100% !important;
}

.position-absolute {
    position: absolute;
}