

/* width */
.sider-layout::-webkit-scrollbar, .sider-layout-collapsed::-webkit-scrollbar{
    width: 5px !important;
    height: 5px !important;
    display: none !important;
}

/* Track */
.sider-layout::-webkit-scrollbar-track, .sider-layout-collapsed::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px $gray-100 !important; 
    border-radius: 10px !important;
}

/* Handle */
.sider-layout::-webkit-scrollbar-thumb, .sider-layout-collapsed::-webkit-scrollbar-thumb {
    background: $primary-color !important; 
    border-radius: 10px !important;
    // visibility: hidden !important;
}

/* Handle on hover */
.sider-layout::-webkit-scrollbar-thumb:hover, .sider-layout-collapsed::-webkit-scrollbar-thumb:hover {
    background: darken($color: $primary-color, $amount: 10) !important; 
}

.sider-layout {
    height: calc(100vh - $header-height - 195px);
}

.sider-layout-collapsed {
    height: calc(100vh - $header-height - 110px);
}

.sider-layout, .sider-layout-collapsed {

    // height: calc(100vh - $header-height);
    position: fixed !important;
    top: $header-height;
    background: $white !important;
    overflow-y: auto;
    border-right: 1px solid #f0f0f0 !important;

    .navigation-button {
        height: 40px !important;
        background-color: $primary-color !important;
        padding: 0px 16px !important;
        color: $white !important;
        cursor: pointer !important;
    }

    ul {
        background: $white !important;
        color: $black !important;
        border-right: 0px !important;

        li {
            color: $black !important;
            align-items: center !important;
        }
    }

    // .ant-menu-submenu-selected {
    //     background: $white !important;
    //     color: $primary-color !important;

    //     .ant-menu-submenu-arrow {
    //         color: $primary-color !important;
    //     }
    // }
}


.ant-menu-item-active, .ant-menu-item-selected {
    background-color: $white !important;
    .anticon {
        color: $primary-color !important;
    }
}

.sidebar-menu-item, .collapse-sidebar-menu-item {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

// .submenu-layout {
//     overflow-x: auto !important;
// }

// Sider Footer
.sider-footer {
    width: $sider-width !important;
    height: 195px !important;
    position: fixed !important;
    bottom: 0;
}

.sider-footer-collapsed {
    width: $sider-collapsed-width !important;
    height: 110px !important;
    position: fixed !important;
    bottom: 0;
}

.sider-footer, .sider-footer-collapsed {
    // position: fixed !important;
    // bottom: 0 !important;
    background-color: $white !important;
    border-right: 1px solid #f0f0f0 !important;
    transition: 0.2s;
}